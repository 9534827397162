<template>
  <div class="session-wrapper mt-4">
		<div class="session-center text-xs-center mt-4" >
			<div class="session-content mt-4">
				<img :src="appLogo" class="img-responsive mt-4 mb-3" width="300px" height="300px" />

				<v-container fill-height grid-list-md text-xs-center>
					<v-layout row wrap align-center text-xs-center>
						<v-flex xs4></v-flex>
						<v-flex xs4>
						<h2 class="mb-3">{{$t('message.forgotPassword')}}</h2>
						<p class="fs-14">{{$t('message.enterYourEmailToSendYouAResetLink')}}.</p>
						<v-form v-model="valid" class="mb-4">
								<v-text-field 
								label="E-mail ID" 
								v-model="email" 
								:rules="emailRules" 
								required
							></v-text-field>						
							<v-btn color="primary" block @click="" to="/session/reset-password"	>Send Email</v-btn>
						</v-form>
						<div>
							<router-link to="/session/login">{{$t('message.backToSignIn')}}</router-link>
						</div>
						</v-flex>
						<v-flex xs4></v-flex>
					</v-layout>
				</v-container>

				</div>
			</div>
		</div>
  </div>
</template>


<script>
import SessionSliderWidget from "Components/Widgets/SessionSlider";
import AppConfig from "Constants/AppConfig";

export default {
  components: {
    SessionSliderWidget
  },
  data() {
    return {
			email: '',
      valid: false,
			emailRules: [
        v => !!v || "E-mail is required",
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid"
      ],
      appLogo: AppConfig.appLogo2
    };
  }
};
</script>
